import { Form, Field } from "react-final-form";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Snackbar,
  Alert,
  TextField
} from "@mui/material";
import Input from "./input";
import Select from "./select";
import { useRef, useState } from "react";
import {
  useGetServicePartQuery,
  useGetServiceQuery,
  useGetLcidsQuery,
} from "features/translations/api";

const Translations = (props) => {
  const [toast, setToast] = useState(false);
  const { data: lcids } = useGetLcidsQuery();
  const { data: serviceParts } = useGetServicePartQuery();
  const services = ["HALDOR_EDUCATION", "HALDOR_GUARDIANS"];

  // const validateInput = (value) => {
  //   if (value == "" || value == null) {
  //     return "Field is empty";
  //   }
  //   if (value.length < 1) {
  //     return "Too short search term";
  //   }
  //   return undefined;
  // };

  const validateInput = (value) => {
    
    const invalidUrlCharRegex = /[^a-zA-Z0-9-_~.,! `´]/;

  
    if (!value || value.trim() === '') {
      return 'Invalid input';
    }
    if (value.length < 1) {
      return 'Input value is too short';
    }
    if (invalidUrlCharRegex.test(value)) {
      return 'Input contains invalid characters';
    }
    
    return undefined; 
  };

  

  return (
    <Form
      onSubmit={(value, form) => {
        console.log(value)
        props.onSubmit(value);
         

        
        
      }}
      initialValues={props.translation}
      render={({ handleSubmit, valid }) => (
        <form onSubmit={handleSubmit}>
          <Table>
            

            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: '10px 5px', border: '0px'}}>
                    <Field
                      
                      name="id"
                      validate={validateInput}
                      render={({ input, meta }) => {
                        
                        return (
                          <TextField
                            {...input}
                            fullWidth
                            variant="outlined"
                            placeholder="Enter id"
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                            style={{ backgroundColor: "white", height: "50px",marginBottom:"10px" }}
                          />
                        );
                      }}
                      
                    />
                  </TableCell>

                  <TableCell sx={{ padding: '10px 5px', border: '0px' }}>
                    <Field
                      name="text"
                      validate={validateInput}
                      render={({ input, meta }) => (
                        <TextField
                          {...input}
                          fullWidth
                          variant="outlined"
                          placeholder="Enter text"
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                          style={{ backgroundColor: "white", height: "50px" ,marginBottom:"10px"}}
                        />
                      )}
                    />
                  </TableCell>

                <TableCell style={{ padding:2 , borderBottom: "none"}}>
                  {lcids != null ? (
                    <Field
                      validate={validateInput}
                      name="lcid"
                      component={Select}
                      style={{ backgroundColor: "white", height: "60px",marginBottom:"10px" }}
                      aria-label="LCID select"
                      options={[
                        { label: "Select LCID" },
                        ...lcids.map((lcid) => {
                          return { label: lcid, value: lcid };
                        }),
                      ]}
                    />
                  ) : null}
                </TableCell>

                <TableCell style={{ padding:3, borderBottom: "none"}}>
                  {services != null ? (
                    <Field
                      validate={validateInput}
                      name="service"
                      component={Select}
                      style={{ backgroundColor: "white" , height: "60px",marginBottom:"10px"}}
                      aria-label="Service select"
                      options={[
                        { label: "Select service" },
                        ...services.map((service) => {
                          return { label: service, value: service };
                        }),
                      ]}
                    />
                  ) : null}
                </TableCell>

                <TableCell style={{ padding:3 , borderBottom: "none"}}>
                  {serviceParts != null ? (
                    <Field
                      validate={validateInput}
                      name="servicePart"
                      component={Select}
                      style={{ backgroundColor: "white", height: "60px",marginBottom:"10px"}}
                      aria-label="Service part select"
                      options={[
                        { label: "Select Servicepart" },
                        ...serviceParts.map((servicePart) => {
                          return { label: servicePart, value: servicePart };
                        }),
                      ]}
                    />
                  ) : null}
                </TableCell>

                <TableCell style={{padding: "0px 0px", borderBottom: "none"}}>
                  <Button

                    
                    color="primary"
                    size="large"
                    type="submit"
					          variant="contained" 
                    style={{ borderRadius: "6px", margin: "0px 0px", color:"white"  , height: "57px",cursor: "pointer",marginBottom:"10px"}}
                    disabled={!valid}
                  >
                    Submit
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          
          
        </form>
      )}
    />
  );
};

export default Translations;
